<template>
  <Suspense>
    <template #default>
      <router-view></router-view>
    </template>

    <template #fallback>
      <PageLoaderVue></PageLoaderVue>
    </template>
  </Suspense>
</template>
<script>
  import PageLoaderVue from "@/components/PageLoader.vue";
  export default {
    name: "HomeView",
    components: {
      PageLoaderVue,
    },
    setup() {
      return {};
    },
    data() {
      return {};
    },
    beforeCreate() {},
    beforeMount() {
      this.isloading = false;
    },
    mounted() {
      this.isloading = false;
    },
  };
</script>

<style lang="scss">
  @import "@/styles/main";
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  #app {
    font-family: font-style("font-8"), Helvetica, Arial, sans-serif;
    //   -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
    //   text-align: center;
    color: color("light");
  }

  // nav {
  //   padding: 30px;
  // }
  s
// nav a {
//   font-weight: bold;
//   color: #2c3e50;
// }

// nav a.router-link-exact-active {
//   color: #42b983;
// }

.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
