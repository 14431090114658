<template>
  <div class="main-container" @scroll="handleScroll">
    <Header></Header>
        <!-- <Suspense>
            <template #default>
              <router-view v-slot="{ Component }">
                  <transition name="fade" mode="out-in">
                      <component :is="Component" />
                  </transition>
              </router-view>
            </template>

            <template #fallback>
              <PageLoaderVue/>
          </template>
        </Suspense> -->


        <PageLoaderVue v-if="this.isLoading"/>

        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    <Footer></Footer>
  </div>
</template>

<script>
/* eslint-disable */

import {ref }  from 'vue'
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import PageLoaderVue from '@/components/PageLoader.vue';
import { api } from '@/service/webapi'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    PageLoaderVue,
  },
  async setup(){
    return {
    }
  },
  data(){
    return {
      isLoading:false
    }
  },
  mounted(){
    window.addEventListener('scroll', this.reveal);

  },
  methods:{
    getVideoUrl(path) {
      let videos = require.context('@/assets/', false, /\.mp4$/);
      return videos('./' + path);
    },
    setImgUrl(item){
            if(item!=null){
                return `https://egc.ap-south-1.linodeobjects.com/walk-online-bucket/${item}`
            }
            return ''
        },
    reveal(){
          const elements = document.querySelectorAll('.hidden');
        for(var i = 0;i<elements.length;i++){
          var windowHeight = window.innerHeight;
          var revealTop = elements[i].getBoundingClientRect().top;
          var revealpoint = 150;

          if(revealTop < windowHeight - revealpoint)
            elements[i].classList.add('show')
          else 
            elements[i].classList.remove('show')

        }
    },
    async handleScroll(event) {
        const container = event.target;
        const scrollTop = container.scrollTop;
        const itemHeight = container.clientHeight;
        // Calculate the index of the snapped item
        const index = Math.round(scrollTop / itemHeight);

        // Scroll to the snapped item
        container.scrollTop = index * itemHeight;
      },
  
  }
}


</script>


<style lang="scss" scoped>

.video-loader{
  width:100vw;
  height:100vh;
  position:relative;
  animation:fade-out 4s forwards;
  video {
    width:100%;
    height:100%;
    object-fit: cover;
    
  }
}
@keyframes fade-out {
  0%{
    opacity:1;
  }
  70% {
    opacity:1;
  }
  100% {
    opacity:0
  }
}


    .main-container{
      background-color:hsl(72, 16%, 6%);
      background-repeat:no-repeat;
      background-clip: border-box;
      background-attachment: fixed;
      background-size:cover;
        // background:linear-gradient(to right, #251f34,30% , #0a0713);
        isolation:isolate;
        position:relative;
        width:100%;
        overflow:hidden;
    }
    
    .n-loading-bar-container{
      --n-color-loading: #0f0442 !important;
      background:#0f0442 !important;
    }
    .n-loading-bar{
      --n-color-loading: #0f0442 !important;
      background:#0f0442 !important;
    }
</style>
