import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery/src/jquery.js'
import '@fortawesome/fontawesome-free/css/all.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import naive from 'naive-ui'
import VueSweetalert2 from 'vue-sweetalert2'
import loginService from "node-back-client/src/services/login-service";
const app_id = "a4853ce7-46b9-4b22-a878-cb6a45f3223a";
loginService.init(router, app_id)

const app = createApp(App)
app.use(store)
app.use(naive)
app.use(router)
app.use(VueSweetalert2)
app.mount('#app')
