<template>
  <div class="container-fluid loader d-flex justify-content-center align-items-center bg-white">
    <div class="logoContainer d-flex justify-content-center align-items-center bg-white">
        <span></span>
        <div class="logoContainer2">
            <span>

            </span>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    props:{
        start:Boolean
    }
}
</script>

<style scoped>
.loader{
    height:100vh;
}
.logoContainer{
    width:170px;
    height:170px;
    border-radius:50%;
    position:relative;
    animation:animate 1.5s linear infinite;
}

.logoContainer:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:50%;
    background: linear-gradient(to top, transparent, rgb(0, 12, 177));
    background-size:150px;
    border-top-left-radius: 85px;
    border-bottom-left-radius: 85px;
    
}
@keyframes animate{
    0%{
        transform:rotate(0deg)
    }
    100%{
        transform:rotate(360deg)
    }
}

.logoContainer span{
    position:absolute;
    top:5px;
    left:5px;
    bottom:5px;
    right:5px;
    border-radius:50%;
    z-index:10;
    background:white;
}

.logoContainer2{
    width:130px;
    height:130px;
    border-radius:50%;
    position:relative;
    animation:animate2 1s linear infinite;
    z-index:18;
}

.logoContainer2:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:50%;
    background: linear-gradient(to top, transparent, rgb(0, 12, 177));
    background-size:150px;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    
}
@keyframes animate2{
    0%{
        transform:rotate(0deg)
    }
    100%{
        transform:rotate(360deg)
    }
}
.logoContainer2 span{
    position:absolute;
    top:5px;
    left:5px;
    bottom:5px;
    right:5px;
    border-radius:50%;
    z-index:19;
    background:white;
}
.loadImg{
    position:absolute;
    z-index:15;
}
</style>