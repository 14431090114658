import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const home = () => import('@/components/Home.vue');
const news = () => import('@/components/News.vue');
const about = () => import('@/components/AboutUs.vue');
const career = () => import('@/components/Career.vue');
const jobs = () => import('@/components/JobsPage.vue');
const projects = () => import('@/components/Projects.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/news/:headline',
        name: 'NewsInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/NewsContent.vue')
        
      },

      {
        path: '/',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: home,
        
      },
      {
        path: '/news',
        name: 'News',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: news,
        
      },
      {
        path: '/company',
        name: 'Company',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component:about,

      },
      {            
        path: '/career',
        name: 'Career',
        component:career,
      },
      {            
        path: '/career/:job_id',
        name: 'job',
        component: jobs,
      },
      {            
        path: '/projects',
        name: 'projects',
        component: projects,
      }
    ]
  },
  
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  },
  {
    path: '/test2',
    name: 'test2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView2.vue')
  },
  {
    path: '/test3',
    name: 'test3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView3.vue')
  },
  {
    path: '/test4',
    name: 'test4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView4.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    meta:{auth:true}
},
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // Array of all async components
  const asyncComponents = [
    home,
    news,
    about,
    career,
    jobs,
    projects
  ];


  // Check if any async component is not resolved
  const notResolved = asyncComponents.some(component => !component.resolved);
  try {
    if (notResolved) {
      console.log('resolving...')
      // If any async component is not resolved, resolve all of them
      Promise.all(asyncComponents.map(component => component())).then(() => {
        console.log('resolved!! Routing...')
        next(); // Proceed to the next navigation
      }).catch(error => {
        console.error("Error loading components:", error);
        next(false); // Prevent navigation in case of an error
      });
    } else {
      next(); // All components are resolved, proceed to the next navigation
    }
  } catch (error) {
    console.error("Error loading components:", error);
    next(false); // Prevent navigation in case of an error
  }
  
});




export default router
